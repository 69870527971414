/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import 'bootstrap/dist/css/bootstrap.min.css';

// landkit theme css file. refers to a bunch of assets also in landkit directory: fonts, images, etc.
import './landkit/src/assets/css/theme.css'
import 'src/styles/global.css';
